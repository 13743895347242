import React, { useState, useEffect } from "react"
import { Container } from "react-bootstrap"
import { graphql, navigate } from "gatsby"
import SEO from "../../../components/seo"
import Footer from "../../../components/Footer"
import AgeGate from "../../../components/AgeGate"
import Cookies from "js-cookie"
import SpecialEvent from "../../../components/EventosLollaMute/SpecialEvent"
import { useContext } from "react"
import { GlobalStateContext } from "../../../context/GlobalContextProvider"

export default function LollaEvents({ location, data }) {
  const [age, setAge] = useState(true)
  let params = new URLSearchParams(location.search)
  const {venues} = useContext(GlobalStateContext)
  const eventId = params.get("eid")
  const venue = {
    name: "lolla",
    title: "DISFRUTÁ LOLLAPALOOZA ARGENTINA CON BUDWEISER",
  }
  useEffect(()=>{
    const lollaVenue = data.allVenues.edges.find(event=>event.node.id === "lolla")
    if(!lollaVenue.node.visible) navigate('/')
  },[])


  // TODO 26/12: Simon dijo que nos van a pasar la data que va en el push
  //   useEffect(() => {
  //     let td = Cookies.get("_td")
  //     window.dataLayer.push({
  //       brand: "Budweiser",
  //       Zone: "SAZ",
  //       country: "ARG",
  //       city: "CABA",
  //       campaign: `${
  //         window.location.search.includes("?cmp=")
  //           ? window.location.search.split("?cmp=")[1].split("&")[0]
  //           : ""
  //       }`,
  //       cms: "0",
  //       event: "pageView",
  //       language: "es",
  //       login: false,
  //       pageName: "La musica en tus manos",
  //       pageType: "Basic page",
  //       SiteType: "Brand",
  //       product: "NA",
  //       sku: "NA",
  //       userUid: "",
  //       url: "https://www.budweiser.com.ar/lamusicaentusmanos/lolla",
  //       url_campaign: `${
  //         window.location.search.includes("utm_campaign=")
  //           ? window.location.search.split("utm_campaign=")[1]
  //           : ""
  //       }`,
  //       Step_number: step ? `step_${step}` : "",
  //     })
  //   }, [step])

  useEffect(() => {
    let cookie = Cookies.get("age")
    let local = sessionStorage.getItem("age")
    // console.log("Cookie",cookie)
    if (cookie || local) setAge(false)
  }, [])

  return !age ? (
    <>
      <div style={{ overflowX: "hidden" }}>
        <SEO title="The Birra behind the music" />
        <Container fluid className="m-0 p-0">
          <section id="EventosSection">
            <SpecialEvent
              venue={venue}
              data={data}
              location={location}
              // eventId={eventId}
            />
          </section>
          <Footer />
        </Container>
      </div>
    </>
  ) : (
    <AgeGate path={location?.pathname + location?.search} />
  )
}

export const data = graphql`
  query thebirrabehindthemusicLolla {
    allLollaEvents(sort: { fields: id, order: ASC }) {
      edges {
        node {
          id
          index
          address
          name
          province
          schedule
          url
          visible
        }
      }
    }
    allVenues(sort: { fields: id, order: ASC }) {
      edges {
        node {
          id
          iconUrl
          location
          left
          leftMobile
          top
          topMobile
          visible
        }
      }
    }
  }
`
